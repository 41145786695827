<template>
    <el-dialog
        width="50%"
        :visible.sync="showDialog"
        :title="title"
        :modal-append-to-body="true"
        :append-to-body="true"
        style="text-align:left;"
        @close="closeDialog"
        :close-on-click-modal="false"
        >
        <div class="user-panel" 
            v-loading="loading"
            :element-loading-text="loadingText"
            element-loading-spinner="el-icon-loading"
        >
        <el-form ref="form" :inline="true" :model="formModel" :rules="ruleValidate" :label-position="labelPosition"  label-width="120px">
            <el-row :gutter="24" class="row" >
                <el-col :span="12">
                    <el-form-item label="申请人">
                    <span class="data">{{formModel.createBy}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="商家名称">
                    <span class="data">{{formModel.merchantName}}</span>
                    </el-form-item>
                 </el-col>
            </el-row>
            <el-row :gutter="24" class="row" >
                <el-col :span="12">
                    <el-form-item label="联系方式">
                    <span class="data">{{formModel.contactPhone}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="开户名称">
                    <span class="data">{{formModel.accountName}}</span>
                    </el-form-item>
                 </el-col>
            </el-row>
            <el-row :gutter="24" class="row" >
                <el-col :span="12">
                    <el-form-item label="开户银行">
                    <span class="data">{{formModel.accountBank}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="账号">
                    <span class="data">{{formModel.accountNumber}}</span>
                    </el-form-item>
                 </el-col>
            </el-row>
            <el-row :gutter="24" class="row" >
                <el-col :span="12">
                    <el-form-item label="联行号">
                    <span class="data">{{formModel.interBankNumber}}</span>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="申请转账金额">
                    <span class="data">{{formModel.applicationFee}}</span>
                    </el-form-item>
                 </el-col>
            </el-row>
            <el-row :gutter="24" class="row" >
                <el-col :span="12">
                    <el-form-item label="实际转账金额">
                        <el-input v-model="formModel.transferFee" placeholder="请输入实际转账金额" ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="转账凭证" prop="transferVoucher">
                      <el-upload
                        class="avatar-uploader"
                        name="photoFile"
                        :action="uploadUrl"
                        :show-file-list="false"
                        :headers="headers"
                        :data="uploadData"
                        :on-success="handleAvatarSuccess"
                      >
                        <img v-if="fileUrl" :src="fileUrl" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                    </el-form-item>
                 </el-col>
            </el-row>
        </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" @click="handleSubmit"  :loading="submitting">确 定</el-button>
        </span>

    </el-dialog>
</template>
<script>
import Constant from "@/constant";
import merchantApplicationRecordApi from '@/api/merchant/merchantApplicationRecord'
import { getToken } from "@/utils/auth"; // get token from cookie
export default {
    props: ["businessKey", "title"],
    data(){
        return{
            formModel:{
                createBy:"",
            },
            ruleValidate: {
                createBy: [{ required: true, message: "路线不能为空", trigger: "blur" }],
            },
            labelPosition: 'right',
            showDialog: true,
            loading: false,
            loadingText: '加载中',
            submitting: false,
            //上传地址
            uploadUrl: Constant.serverUrl + "/uploadPicture",
            headers: {
              Authorization: getToken()
            },
            uploadData: {
              subFolder: "merchantApplicationRecord"
            },
            fileUrl: "",
            downloadLoading: false,
        }
    },
    methods: {
        closeDialog() {
            this.$emit("close", false);
        },
        handleSubmit() {
            var self = this;

            this.$refs["form"].validate(valid => {
                if (valid) {
                    this.loading = true;
                    this.loadingText = "提交中";

                    (function() {
                        var id = self.formModel.id;

                        return merchantApplicationRecordApi.update(self.formModel);
                        
                    })().then(function(response) {
                        var jsonData = response.data;
                        self.loading = false;

                        if (jsonData.result) {
                        self.$message({
                            message: "保存成功!",
                            type: "success"
                        });

                        self.$emit("close", true);
                        } else {
                        self.$message({
                            message: jsonData.message + "",
                            type: "warning"
                        });
                        }
                    });
                }
            });
        },

        handleAvatarSuccess(res, file) {
            var self = this;
            self.formModel.transferVoucher = res.data;
            self.fileUrl =
                res.data + "?x-oss-process=image/resize,m_lfit,h_400,w_400";
            },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
            this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
            this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        }
    },
    async mounted() {
        console.log("mounted");

        var self = this;

        (function() {
            return merchantApplicationRecordApi.edit(self.businessKey);
        })()
        .then(response => {
            var jsonData = response.data;

            if (jsonData.result) {
                self.formModel = jsonData.data;
                let transferVoucher = self.formModel.transferVoucher;
                if (transferVoucher != null) {
                self.fileUrl =
                    transferVoucher + "?x-oss-process=image/resize,m_lfit,h_400,w_400";
                }
            } else {
                self.$message.error(jsonData.message + "");
            }
        })
        .catch(error => {
            self.$message.error(error + "");
        });
    }
    
}
</script>
<style scoped>
.user-panel {
  margin: 10px auto;
  margin-left: 20px;
}

.data{
    margin-left: 20px;
    font-weight:bold
}
.el-row {
margin-bottom: 15px;
}
.el-col {
border-radius: 4px;
}
.fontText{
    width: 250px;
    text-align: right;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 158px;
  height: 158px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 158px;
  height: 158px;
  display: block;
  object-fit: cover;
}
</style>