<template>
    <div class="merchantApplicationRecord-list">
        <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">商家管理</a>
            </el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">商家提现记录</a>
            </el-breadcrumb-item>
        </el-breadcrumb>
        <el-divider></el-divider>
        <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
            <el-form-item label="申请单号" prop="applicationNo">
                <el-input v-model="queryModel.applicationNo" size="mini"/>
            </el-form-item>
            <el-form-item label="选择商家" prop="merchantId">
                <el-select v-model="queryModel.merchantId" placeholder="请选择" size="mini">
                    <el-option
                        v-for="item in merchantList"
                        :key="item.id"
                        :label="item.merchantName"
                        :value="item.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="申请时间" prop="applicationTimeRange">
                <el-date-picker
                    size="mini"
                    v-model="queryModel.applicationTimeRange"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                ></el-date-picker>
            </el-form-item>
            <el-form-item label="申请状态" prop="status">
                <el-select v-model="queryModel.status" placeholder="请选择" size="mini">
                    <el-option
                        v-for="item in statusList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="转账时间" prop="transferTimeRange">
                <el-date-picker
                    size="mini"
                    v-model="queryModel.transferTimeRange"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                ></el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button
                type="primary"
                size="mini"
                icon="ios-search"
                @click="changePage(1)"
                :loading="loading"
                >查询</el-button>&nbsp;
                <el-button
                    type="info"
                    size="mini"
                    style="margin-left: 8px"
                    @click="handleReset('queryForm')"
                >重置</el-button>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <el-row class="button-group">
        <el-button
            type="primary"
            size="small"
            plain
            icon="el-icon-download"
            :loading="downloadLoading"
            @click="downloadXls"
        >导出数据</el-button>
        </el-row>
        <el-table
            ref="formTable"
            :data="tableData"
            v-loading="loading"
            element-loading-text="拼命加载中"
            stripe
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="applicationTime" label="申请时间" fixed="left"></el-table-column>
            <el-table-column prop="applicationNo" label="申请单号" fixed="left"></el-table-column>
            <el-table-column prop="createBy" label="申请人" fixed="left"></el-table-column>
            <el-table-column prop="merchantName" label="商家名称" fixed="left"></el-table-column>
            <el-table-column prop="contactPhone" label="联系方式" fixed="left"></el-table-column>
            <el-table-column prop="applicationFee" label="申请提现金额" fixed="left"></el-table-column>
            <el-table-column prop="status" label="申请单状态" fixed="left"></el-table-column>
            <el-table-column prop="transferFee" label="实际转账金额" fixed="left"></el-table-column>
            <el-table-column prop="transferTime" label="转账时间" fixed="left"></el-table-column>
            <el-table-column prop="transferVoucher" label="转账凭证" >
                <template slot-scope="{row}">
                    <a v-if="row.transferVoucher" :href="row.transferVoucher" target="_blank">
                        <el-image
                        :size="50"
                        :src="row.transferVoucher+'?x-oss-process=image/resize,m_fill,w_64,h_64'"
                        :key="row.id"
                        ></el-image>
                    </a>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="{row}">
                    <el-button size="mini" type="warning" @click="handleEdit(row)">修改</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:center;">
            <el-pagination
                :current-page.sync="pageIndex"
                :total="totalElements"
                :page-sizes="pageSizeList"
                @current-change="changePage"
                @size-change="pageSizeChange"
                layout="total, sizes, prev, pager, next,  jumper"
            ></el-pagination>
        </div>
        <merchantApplicationRecord-detail
            v-if="showModal"
            :businessKey="businessKey"
            :parentId="selectedRecord.id"
            :title="modalTitle"
            @close="onDetailModalClose"
        ></merchantApplicationRecord-detail>
    </div>
</template>
<script>
import merchantApplicationRecordApi from '@/api/merchant/merchantApplicationRecord'
import merchantApplicationRecordDetail from "./merchantApplicationRecord-detail";
import merchantInfoApi from '@/api/merchant/merchantInfo'
export default {
    name:"merchantMerchantApplicationRecordList",
    data(){
         return{
            queryModel:{
                applicationNo:"",
                merchantId:"",
                applicationTimeRange:"",
                status:"",
                transferTimeRange:"",
            },
            statusList:[{
                label:"已提交",
                value:"1"
            },{
                label:"已转账",
                value:"2"
            }],
            merchantList:[],
            loading: false,
            tableData: [],
            multipleSelection: [],
            pageIndex: 1,
            pageSize: 10,
            totalElements: 0,
            pageSizeList: [10,20,30],
            showModal: false,
         }
    },
    methods: {
        changePage(pageIndex) {
            this.loading = true;
            var formData = new FormData();

            formData.append("pageIndex", this.pageIndex);
            formData.append("pageSize", this.pageSize);
            formData.append("applicationNo", this.queryModel.applicationNo);
            formData.append("merchantId", this.queryModel.merchantId);
            formData.append("applicationTimeRange", this.queryModel.applicationTimeRange);
            formData.append("status", this.queryModel.status);
            formData.append("transferTimeRange", this.queryModel.transferTimeRange);


            merchantApplicationRecordApi.pageList(formData).then(response => {
                this.loading = false;
                var jsonData = response.data;

                console.log(jsonData);

                this.tableData = jsonData.data.data;
                this.totalElements = jsonData.data.recordsTotal;

                this.pageIndex = pageIndex;
            });
        },
        pageSizeChange(pageSize) {
            this.pageSize = pageSize;
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleReset(name) {
            this.$refs[name].resetFields();
        },
        handleEdit(record) {
            this.modalTitle = "编辑";
            this.operation = "edit";
            this.businessKey = record.id;
            this.selectedRecord = record;
            this.showModal = true;
        },
        onDetailModalClose(retObj) {
            //保存成功后回调
            this.showModal = false;

            if (retObj.result) {
                var retData = retObj.data;

                if (this.selectedRecord.parentId != retData.parentId) {
                    this.loopDelete(this.tableData, this.selectedRecord.id);

                    var parent = this.loopFind(this.tableData, retData.parentId);

                    if (parent != null) {
                        if (parent.children == null) {
                            parent.children = [];
                        }

                        parent.children.push(retData);
                    }
                }

            }
        },
        loopDelete(list, id) {
            var rs = false;

            for (var i = 0; i < list.length; i++) {
                if (list[i].id == id) {
                list.splice(i, 1);
                rs = true;
                break;
                }

                if (list[i].children != null) {
                rs = this.loopDelete(list[i].children, id);

                if (rs) {
                    break;
                }
                }
            }

            return rs;
        },
        downloadXls() {
            //导出
            var self = this;
            self.downloadLoading = true;
            self.loading = true;
            self.loadingText = "数据导出中，请稍后...";

            var formData = new FormData();

            formData.append("name", this.queryModel.name);
            formData.append("licensePlateNumber", this.queryModel.licensePlateNumber);
            formData.append("happenTimeRanges", this.queryModel.happenTimeRanges);
            formData.append("schoolId", this.queryModel.schoolId);

            var timestamp = new Date().getTime();
            formData.append("timestamp", timestamp);

            formData.append("pageSize", 2000);

            merchantApplicationRecordApi.exportXls(formData).then(function(response) {
                var jsonData = response.data;
                self.downloadLoading = false;
                self.loading = false;
                if (jsonData.result) {
                //下载有错误信息提示的报表
                self.$message({
                    showClose: true,
                    dangerouslyUseHTMLString: true,
                    message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                    duration: 60000,
                    type: "success"
                });
                } else {
                self.$message.error(jsonData.message);
                }
            });
        },
    },
    components: {
        "merchantApplicationRecord-detail": merchantApplicationRecordDetail
    },
    mounted() {
        this.changePage(1);

        var self = this;

        merchantInfoApi.list().then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
                self.merchantList = jsonData.data;
            }
        });
    },
}
</script>
<style scoped>
.merchantApplicationRecord-list{
    text-align: left;
}
.el-breadcrumb {
    margin: 10px;
    line-height: 20px;
}
</style>